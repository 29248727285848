import { type FC } from 'react'

import getServerSidePropsHandler from '@redux/features/homePage/getServerSideProps'

import type { MainPageProps } from '@pages/MainPage'
import MainPage from '@pages/MainPage'

export type Props = Omit<MainPageProps, 'noIndex'>

const RootPage: FC<Props> = props => <MainPage {...props} noIndex />

export const getServerSideProps = getServerSidePropsHandler

export default RootPage
